export class FeriasVerbas {
    Desconto: string;
    Descri: string;
    Vencimento: string;
    Verba: string;

    constructor(Desconto: string, Descri: string, Vencimento: string, Verba: string){
        this.Desconto = Desconto;
        this.Descri = Descri;
        this.Vencimento = Vencimento;
        this.Verba = Verba;
    }
}