import { on } from "process";
import { FeriasVerbas } from "./ferias-verbas";

export class FeriasMeses {
    ano: string;
    descontos: string;
    mes: string;
    mesExtenso: string;
    vencimentos: string;
    verbas: Array<FeriasVerbas>;

    constructor(ano: string, descontos: string, mes: string, mesExtenso: string, vencimentos: string, verbas: Array<FeriasVerbas>) {
        this.ano = ano;
        this.descontos = descontos;
        this.mes = mes;
        this.mesExtenso = mesExtenso;
        this.vencimentos = vencimentos;
        this.verbas = verbas;
    }

    static adaptVerbas(data): Array<FeriasVerbas> {
        let list = new Array<FeriasVerbas>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpObjeto(l));
            });
        }
        return list;
    }


    static adatpObjeto(obj: any): FeriasVerbas {
        let Desconto = obj.Desconto;
        let Descri = obj.Descri;
        let Vencimento = obj.Vencimento;
        let Verba = obj.Verba;

        return new FeriasVerbas(Desconto, Descri, Vencimento, Verba);
    }


}