export class Funcionario {
	idFirebase: string;
	cpf: string;
	nascimento: string;
	nome: string;
	registro: string;
	codEmpresa: string;
	senha: string;
	dtAdm: string;
	funcao: string;
	cnpj: string;
	cnpj2: string;
	empresa: string;
	trocaSenha: string;
	email: string;
	telefone: string;
	celular: string;
	token: string;
	comBcoHr: string;
	comFolha: string;
	comIR: string;
	comVr: string;
	comunicaEscala: string;
	comPerfil: string;
	atuaDados: string;
	lerTermo: string;
	dddFone: string;
	dddCel: string;
	comDownFolha: string;
	pis: string;
	comRelogioPto: string;
	comFotoPerfil: string;
	comPerfilEdita: string;
	imagem: string;
	comEditaAtalho: string;
	comEscala65: string;
	comReciboFerias: string;
	comSacFolha: string;
	comMapaItin: string;

	// Método construtor do objeto
	constructor(id: string, cpf: string, nascimento: string, nome: string, registro: string, codEmpresa: string, senha: string, dtAdm: string, funcao: string, cnpj: string,
		cnpj2: string, empresa: string, trSenha: string, email: string, telefone: string, celular: string, token: string, comBcoHr: string, comFolha: string, comIR: string,
		comVr: string, comunicaEscala: string, comPerfil: string, atuaDados: string, lerTermo: string, dddFone: string, dddCel: string, comDownFolha: string, pis: string, 
		comRelogioPto: string, comFotoPerfil: string, comPerfilEdita: string, imagem: string, comEditaAtalho: string, comEscala65: string, comReciboFerias: string, comSacFolha: string,
		comMapaItin: string) {
		this.idFirebase = id;
		this.cpf = cpf;
		this.nascimento = nascimento;
		this.nome = nome;
		this.registro = registro;
		this.codEmpresa = codEmpresa;
		this.senha = senha;
		this.dtAdm = dtAdm;
		this.funcao = funcao;
		this.cnpj = cnpj;
		this.cnpj2 = cnpj2;
		this.empresa = empresa;
		this.trocaSenha = trSenha;
		this.email = email;
		this.telefone = telefone;
		this.celular = celular;
		this.token = token;
		this.comBcoHr = comBcoHr;
		this.comFolha = comFolha;
		this.comIR = comIR;
		this.comVr = comVr;
		this.comunicaEscala = comunicaEscala;
		this.comPerfil = comPerfil;
		this.atuaDados = atuaDados;
		this.lerTermo = lerTermo;
		this.dddFone = dddFone;
		this.dddCel = dddCel;
		this.comDownFolha = comDownFolha;
		this.pis = pis;
		this.comRelogioPto = comRelogioPto;
		this.comFotoPerfil = comFotoPerfil;
		this.comPerfilEdita = comPerfilEdita;
		this.imagem = imagem;
		this.comEditaAtalho = comEditaAtalho;
		this.comEscala65 = comEscala65;
		this.comReciboFerias = comReciboFerias;
		this.comSacFolha = comSacFolha;
		this.comMapaItin = comMapaItin
	}

	static adapts(obj: any): Funcionario {
		let idFirebase = obj.key;
		let cpf = obj.CPF;
		let nascimento = obj.DataNcsto;
		let codEmpresa = obj.Empresa;
		let nome = obj.Nome;
		let registro = obj.Registro;
		let senha = obj.Senha;
		let dtAdm = obj.Data_Admissao;
		let funcao = obj.Funcao;
		let cnpj = obj.Cnpj_Empresa;
		let cnpj2 = '';
		if (obj.Cnpj_Empresa != null && obj.Cnpj_Empresa != undefined) {
			cnpj2 = this.configCnpj(obj.Cnpj_Empresa);
		}
		let empresa = obj.Nome_Empresa;
		let trocaSenha = obj.Trocar_Senha;
		let email = obj.Email;
		let telefone = obj.Telefone;
		let celular = obj.Celular
		let token = obj.Token;
		let comBcoHr = obj.App_Com_BcoHr;
		let comFolha = obj.App_Com_Folha;
		let comIR = obj.App_Com_Ir;
		let comVr = obj.App_Com_Vr;
		let comunicaEscala = obj.App_Com_Comunicacao;
		let comPerfil = obj.App_Com_Perfil;
		let atuaDados = obj.Atualizar_Dados;
		let lerTermo = obj.Ler_Termo;
		let dddFone = obj.DDDTelefone;
		let dddCel = obj.DDDCelular;
		let comDownFolha = obj.App_Com_DownFolha;
		let pis = obj.PisPasep;
		let comRelogioPto = obj.App_Com_RelogioPto;
		let comFotoPerfil = obj.App_Com_Perfil_Foto;
		let comPerfilEdita= obj.App_Com_Perfil_Edita;
		let imagem = obj.Imagem;
		let comEditaAtalho = obj.App_Com_Edita_Atalho; 
		let comEscala65 =  obj.App_Com_Escala_Ds65;
		let comReciboFerias = obj.App_Com_Recibo_Ferias;
		let comSacFolha = obj.App_Com_Sac_Folha;
		let comMapaItin = obj.App_Com_Map_Itin;


		return new Funcionario(idFirebase, cpf, nascimento, nome, registro, codEmpresa, senha, dtAdm, funcao, cnpj, cnpj2, empresa, trocaSenha, email, telefone,
			celular, token, comBcoHr, comFolha, comIR, comVr, comunicaEscala, comPerfil, atuaDados, lerTermo, dddFone, dddCel, comDownFolha, pis, comRelogioPto,
			comFotoPerfil, comPerfilEdita, imagem, comEditaAtalho, comEscala65, comReciboFerias, comSacFolha, comMapaItin);
	}

	static configCnpj(value: string) {
		return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
	}

}