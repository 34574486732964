import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Meses } from 'src/models/mes';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertModalPage } from 'src/shared/components/alert-modal/alert-modal';
import { ModalTutorialPage } from 'src/pages/system/modal-tutorial/modal-tutorial';
import { ModalSobrePagePage } from 'src/pages/system/modal-sobre-page/modal-sobre-page';

@Injectable({
  providedIn: 'root'
})
export class DmgeralService {
  isLoading = false;
  meses: Array<Meses>;

  constructor(private router: Router, public toastController: ToastController, private alertCtrl: AlertController,
    public loadingCtrl: LoadingController, public formBuilder: FormBuilder, private modalController: ModalController,
    private modalCtrl: ModalController) { }

  async openTost(message: string, seg: number) {
    const toast = await this.toastController.create({
      message: message,
      duration: seg * 1000,
      cssClass: 'toast-1',
      color: 'dark'
    });
    toast.present();
  }

  async openTostDanger(message: string, seg: number) {
    const toast = await this.toastController.create({
      message: message,
      duration: seg * 1000,
      cssClass: 'toast-1',
      color: 'danger'
    });
    toast.present();
  }

  async openTostTop(message: string, seg: number) {
    const toast = await this.toastController.create({
      message: message,
      duration: seg * 1000,
      cssClass: 'toast-1',
      position: 'top',
      color: 'dark'
    });
    toast.present();
  }

  async openTostNetwork() {
    const toast = await this.toastController.create({
      message: 'Sem conexão com a internet!',
      duration: 3000,
      cssClass: 'toast-1',
      color: 'danger'
    });
    toast.present();
  }

  async abreAlerta(titulo: string, subTitulo: string) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      subHeader: subTitulo,
      buttons: ['OK']
    });

    await alert.present();
  }

  async abreAlertaToHome(titulo: string, subTitulo: string) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      message: subTitulo,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/home']);
          }
        }
      ]
    });
    await alert.present();
  }

  async abreAlertaCloseModal(titulo: string, subTitulo: string) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      message: subTitulo,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.modalController.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  async abreAlertaToLogin(titulo: string, subTitulo: string) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      message: subTitulo,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/login']);
          }
        }
      ]
    });
    await alert.present();
  }

  async openModalTutorial(newLink: string) {
    if (newLink) {
      const modal = await this.modalCtrl.create({
        component: ModalTutorialPage,
        swipeToClose: true,
        componentProps: {
          link: newLink
        }
      });
      return await modal.present();
    }
  }


  async abreLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: 'Aguarde, carregando...',
      translucent: true
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
        }
      });
    });
  }

  async fechaLoading() {
    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingCtrl.dismiss();
    }
    return null
  }

  openPage(page: string) {
    this.router.navigate([page]);
  }

  pad(palavra: string, size: number): string {
    let s = palavra + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  formatCPF(temp) {
    return formatCPF(temp, { pad: true });
  }

  //funcções de data
  getAno() {
    let date = new Date();
    let ano = date.getFullYear();
    return ano;
  }

  getMes() {
    let date = new Date();
    let mes = (date.getMonth() + 1);
    return mes;
  }

  getArrayMeses() {
    this.meses = [
      { mes: '1', mes_extenso: 'Janeiro' },
      { mes: '2', mes_extenso: 'Fevereiro' },
      { mes: '3', mes_extenso: 'Março' },
      { mes: '4', mes_extenso: 'Abril' },
      { mes: '5', mes_extenso: 'Maio' },
      { mes: '6', mes_extenso: 'Junho' },
      { mes: '7', mes_extenso: 'Julho' },
      { mes: '8', mes_extenso: 'Agosto' },
      { mes: '9', mes_extenso: 'Setembro' },
      { mes: '10', mes_extenso: 'Outubro' },
      { mes: '11', mes_extenso: 'Novembro' },
      { mes: '12', mes_extenso: 'Dezembro' }
    ]
    return this.meses;
  }

  getDataAtual() {
    let date = new Date();
    let hoje = date.getFullYear()
      + '/'
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + '/'
      + ('0' + date.getDate()).slice(-2);
    return hoje;
  }

  getDataAtualBr() {
    let date = new Date();
    let hoje = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    return hoje;
  }


  getData() {
    let date = new Date();
    let hoje =
      ('0' + date.getDate()).slice(-2)
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + date.getFullYear();
    return hoje;
  }

  getDataCompleta() {
    let date = new Date();
    let hoje = ('0' + date.getDate()).slice(-2)
      + '/'
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + '/'
      + date.getFullYear()
      + ' '
      + date.getHours()
      + ':'
      + ('0' + (date.getMinutes())).slice(-2)
    return hoje;
  }

  getDataReduzia() {
    let date = new Date();
    let hoje = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2);
    return hoje;
  }

  getDtAniver(dt: string) {
    let data = new Date(dt);
    let hoje = ('0' + data.getDate()).slice(-2) + '/' + ('0' + (data.getMonth() + 1)).slice(-2);
    return hoje;
  }

  getHora() {
    let date = new Date();
    let hora = date.getHours() + ':' + ('0' + (date.getMinutes())).slice(-2);
    return hora;
  }

  getDtId() {
    let date = new Date();
    let hoje = ('0' + date.getDate()).slice(-2)
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + date.getFullYear()
      + date.getHours()
      + ('0' + (date.getMinutes())).slice(-2)
    return hoje
  }

  getDtIdPro() {
    let date = new Date();
    let isLength = 2;
    let isChar = '0123456789';
    var result = '';
    for (var i = isLength; i > 0; --i) result += isChar[Math.floor(Math.random() * isChar.length)];

    let hoje = date.getFullYear().toString().substr(-2)
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + ('0' + date.getDate()).slice(-2)
      + date.getHours()
      + ('0' + (date.getMinutes())).slice(-2)
      + + ('0' + (date.getSeconds())).slice(-2)
    return hoje + result;
  }

  getWeekDayExt(data: string): string {
    let newDate = new Date(data);
    let nome = "--";

    let weekdays = new Array(7);
    weekdays[0] = "domingo";
    weekdays[1] = "segunda-feira";
    weekdays[2] = "terça-feira";
    weekdays[3] = "quarta-feira";
    weekdays[4] = "quinta-feira";
    weekdays[5] = "sexta-feira";
    weekdays[6] = "sábado";

    nome = weekdays[newDate.getDay()];

    return nome;
  }

  getConverteDt2(dt: string) {
    let data = new Date(dt);
    let hoje = ('0' + data.getDate()).slice(-2) + '/' + ('0' + (data.getMonth() + 1)).slice(-2) + '/' + data.getFullYear()
    return hoje;
  }

  getDtTransform(dt: string) {
    let hoje = dt.replace('/', '').replace('/', '');
    return hoje;
  }


  validaForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      if (controle instanceof FormGroup) {
        this.validaForm(controle);
      }
    });
  }

  async openAlertModal(title: string, subtitle: string, message: string, nro: string, type: string) {
    const modal = await this.modalController.create({
      component: AlertModalPage,
      cssClass: 'modal-transparency',
      componentProps: {
        titulo: title,
        subtitulo: subtitle,
        mensagem: message,
        numero: nro,
        tipo: type
      }
    });
    return await modal.present();
  }

  returnIconColor(color: string) {
    let iconGen: any;
    if (color === 'red') {
      iconGen = {
        iconUrl: 'https://ngsservicos.com/ngs/imagens/point2.png'
        , iconSize: [30, 30]
        , iconAnchor: [22, 35]
        , popupAnchor: [0, -45]
      }
    } else if (color === 'green') {
      iconGen = {
        iconUrl: 'https://ngsservicos.com/ngs/imagens/point4.png'
        , iconSize: [30, 30]
        , iconAnchor: [22, 35]
        , popupAnchor: [0, -45]
      }
    } else if (color === 'yellow') {
      iconGen = {
        iconUrl: 'https://ngsservicos.com/ngs/imagens/point3.png'
        , iconSize: [30, 30]
        , iconAnchor: [22, 35]
        , popupAnchor: [0, -45]
      }
    }

    return iconGen;
  }



}
