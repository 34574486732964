export class SubMenu {
    idFirebase: string;
    title: string;
    url: string;
    icon: string;
    color: string;
    acesso: string;
    sobre: string;
    id: string;
    linkExt: string;
    infoExt: string;
    linkTutorial: string;
    msgIntroducao: string;
    msgVazio: string;
    linkApoio: string;

    constructor(idFirebase: string, titulo: string, url: string, icone: string, cor: string, acesso: string, sobre: string, id: string, linkExt: string,
        infoExt: string, linkTutorial: string, msgIntroducao: string, msgVazio: string, linkApoio: string) {
        this.idFirebase = idFirebase;
        this.title = titulo;
        this.url = url;
        this.icon = icone;
        this.color = cor;
        this.acesso = acesso;
        this.sobre = sobre;
        this.id = id;
        this.linkExt = linkExt;
        this.infoExt = infoExt;
        this.linkTutorial = linkTutorial;
        this.msgIntroducao = msgIntroducao;
        this.msgVazio = msgVazio;
        this.linkApoio = linkApoio;
    }
}