import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-tutorial',
  templateUrl: './modal-tutorial.html',
  styleUrls: ['./modal-tutorial.scss'],
})
export class ModalTutorialPage implements OnInit {
  @Input() link: string;
  sanitizedURL: SafeResourceUrl;

  constructor(public modalController: ModalController, public domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.sanitizedURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.link);
  }
  


}
