export const environment = {
   production: false,
   config: {
      empresa: {
         emp: 2,
         dominioEmp: '@mob.srv.br',
         emailEmp: 'mob@mob.srv.br',
         termoPrivacy: 'https://ngsservicos.com/aplicativos/NgFuncionario/mob/arquivos/mobinfo.html',
         htmlVersao: 'https://ngsservicos.com/aplicativos/NgFuncionario/mob/versoes/news',
         htmlInfoMedalha: 'https://ngsservicos.com/aplicativos/NgFuncionario/mob/arquivos/medalhas.html',
         versaoApp: '1.2.15',
         nomeApp: 'MOB Info',
         versaoMenu: 'V4',
         nomeEmpresa: 'MOB',
         baseUrl: ''
      },
      firebase: {
         apiKey: "AIzaSyBBfgB9qpQsV1E8PaPAc4btuD3tLgdlm1k",
         authDomain: "appmob-7bb70.firebaseapp.com",
         databaseURL: "https://appmob-7bb70.firebaseio.com",
         projectId: "appmob-7bb70",
         storageBucket: "appmob-7bb70.appspot.com",
         messagingSenderId: "234520328628"
      },
      mensagens: {
         msgRecSenha: 'E-mail não encontrado! Procure a empresa para atualizar o seu e-mail.',
         msgRecSenha2: 'Você receberá em alguns minutos um e-mail com a nova senha de acesso. Qualquer dúvida entrar em contato com a empresa.'
      },
      style: {
         logoEmpresa: 'https://ngsservicos.com/aplicativos/NgFuncionario/mob/imagens/logoEmp.png',
         logoEmpresa2: 'https://ngsservicos.com/aplicativos/NgFuncionario/mob/imagens/empresas.png',
         iconeApp: 'https://ngsservicos.com/aplicativos/NgFuncionario/mob/imagens/icon.png',
         iconeApp2: '',
         corEmpresa: 'color11',
         corMenu: 'color21',
         corMenu1: 'color22',
         corMenu2: 'color21',
         varCSS: 'logoMob',
         varCSS2: 'logoEmps',
         varCSS3: 'scroll-content',
         varCSS4: 'img-menu-mob'
      }
   }
};
