import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGruard } from 'src/guards/auth-gruard';
import { AuthLoginGuard } from 'src/guards/auth-login';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    //path: 'agenda/:id',
    path: 'agenda',
    loadChildren: () => import('../pages/consultas/agenda/agenda/agenda.module').then(m => m.AgendaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'agenda-detail',
    loadChildren: () => import('../pages/consultas/agenda/agenda-detail/agenda-detail.module').then(m => m.AgendaDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'avarias',
    loadChildren: () => import('../pages/servicos/avarias/avarias.module').then(m => m.AvariasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'banco-horas',
    loadChildren: () => import('../pages/folha-pagamento/banco-horas/banco-horas.module').then(m => m.BancoHorasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'comunicacao-consulta',
    loadChildren: () => import('../pages/comunicacao/comunicacao-escala/comunicacao-consulta/comunicacao-consulta.module').then(m => m.ComunicacaoConsultaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'comunicacao-interacao',
    loadChildren: () => import('../pages/comunicacao/comunicacao-escala/comunicacao-interacao/comunicacao-interacao.module').then(m => m.ComunicacaoInteracaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'comunicacao-novo',
    loadChildren: () => import('../pages/comunicacao/comunicacao-escala/comunicacao-novo/comunicacao-novo.module').then(m => m.ComunicacaoNovoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'consulta-horarios',
    loadChildren: () => import('../pages/consultas/consulta-horario/consulta-horario.module').then(m => m.ConsultaHorarioPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'consumo-diesel',
    loadChildren: () => import('../pages/consultas/diesel/consumo-diesel/consumo-diesel.module').then(m => m.ConsumoDieselPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'consumo-detail',
    loadChildren: () => import('../pages/consultas/diesel/consumo-diesel-detail/consumo-diesel-detail.module').then(m => m.ConsumoDieselDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'contato',
    loadChildren: () => import('../pages/consultas/contatos/contato/contato.module').then(m => m.ContatoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'diretriz',
    loadChildren: () => import('../pages/consultas/diretriz/diretriz.module').then(m => m.DiretrizPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'escala',
    loadChildren: () => import('../pages/escalas/escala-padrao/escala/escala.module').then(m => m.EscalaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'folha-pgto',
    loadChildren: () => import('../pages/folha-pagamento/folha-pgto/folha-pgto.module').then(m => m.FolhaPgtoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'fundacao',
    loadChildren: () => import('../pages/consultas/fundacao/fundacao.module').then(m => m.FundacaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'home',
    loadChildren: () => import('../pages/system/home/home.module').then(m => m.HomePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'img-rec',
    loadChildren: () => import('../pages/system/img-rec/img-rec.module').then(m => m.ImgRecPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'info-contato',
    loadChildren: () => import('../pages/consultas/contatos/info-contato/info-contato.module').then(m => m.InfoContatoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'info-page',
    loadChildren: () => import('../pages/system/info-page/info-page.module').then(m => m.InfoPagePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'info-trecho',
    loadChildren: () => import('../pages/servicos/info-trecho/info-trecho.module').then(m => m.InfoTrechoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'jornal-visate',
    loadChildren: () => import('../pages/comunicacao/informativos/jornal-visate/jornal-visate.module').then(m => m.JornalVisatePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/system/login/login.module').then(m => m.LoginPageModule),
    canLoad: [AuthLoginGuard]
  },
  {
    path: 'manual-colab',
    loadChildren: () => import('../pages/consultas/manual-colab/manual-colab.module').then(m => m.ManualColabPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'mensagem',
    loadChildren: () => import('../pages/comunicacao/mensagens/msg-padrao/mensagem/mensagem.module').then(m => m.MensagemPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'modal-generic',
    loadChildren: () => import('../pages/system/modal-generic/modal-generic.module').then(m => m.ModalGenericPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'msg-texto',
    loadChildren: () => import('../pages/comunicacao/mensagens/msg-padrao/msg-texto/msg-texto.module').then(m => m.MsgTextoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'noticias',
    loadChildren: () => import('../pages/comunicacao/informativos/noticias/noticias.module').then(m => m.NoticiasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'notificacao',
    loadChildren: () => import('../pages/system/notificacao/notificacao.module').then(m => m.NotificacaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('../pages/system/perfil/perfil.module').then(m => m.PerfilPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'pontos',
    loadChildren: () => import('../pages/escalas/escala-padrao/pontos/pontos.module').then(m => m.PontosPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'reconhecimento',
    loadChildren: () => import('../pages/consultas/reconhecimentos/reconhecimento/reconhecimento.module').then(m => m.ReconhecimentoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'reconhecimento-detail',
    loadChildren: () => import('../pages/consultas/reconhecimentos/reconhecimento-detail/reconhecimento-detail.module').then(m => m.ReconhecimentoDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'recupera-senha',
    loadChildren: () => import('../pages/system/recupera-senha/recupera-senha.module').then(m => m.RecuperaSenhaPageModule),
  },
  {
    path: 'sobre',
    loadChildren: () => import('../pages/system/sobre/sobre.module').then(m => m.SobrePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'social',
    loadChildren: () => import('../pages/consultas/social/social.module').then(m => m.SocialPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'solicitacao',
    loadChildren: () => import('../pages/servicos/solicitacoes/solicitacao/solicitacao.module').then(m => m.SolicitacaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'troca-senha',
    loadChildren: () => import('../pages/system/troca-senha/troca-senha.module').then(m => m.TrocaSenhaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'vale-refeicao',
    loadChildren: () => import('../pages/folha-pagamento/vale-refeicao/vale-refeicao.module').then(m => m.ValeRefeicaoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'vcto-folha',
    loadChildren: () => import('../pages/folha-pagamento/vcto-folha/vcto-folha.module').then(m => m.VctoFolhaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'viagens',
    loadChildren: () => import('../pages/escalas/escala-padrao/viagens/viagens.module').then(m => m.ViagensPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'cipa',
    loadChildren: () => import('../pages/servicos/cipa/cipa/cipa.module').then(m => m.CipaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'cipa-detail',
    loadChildren: () => import('../pages/servicos/cipa/cipa-detail/cipa-detail.module').then(m => m.CipaDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'saldo-banco',
    loadChildren: () => import('../pages/folha-pagamento/saldo-banco/saldo-banco.module').then(m => m.SaldoBancoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'ponto-eletronico',
    loadChildren: () => import('../pages/servicos/ponto-eletronico/ponto-eletronico/ponto-eletronico.module').then(m => m.PontoEletronicoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'doc-digital',
    loadChildren: () => import('../pages/servicos/doc-digital/doc-digital.module').then(m => m.DocDigitalPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'planilha-pto',
    loadChildren: () => import('../pages/folha-pagamento/planilha-pto/planilha-pto.module').then(m => m.PlanilhaPtoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'tripulacoes',
    loadChildren: () => import('../pages/escalas/escala-padrao/tripulacoes/tripulacoes.module').then( m => m.TripulacoesPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'info-trecho-visate',
    loadChildren: () => import('../pages/servicos/info-trecho-visate/info-trecho-visate.module').then( m => m.InfoTrechoVisatePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'extrato-horas',
    loadChildren: () => import('../pages/folha-pagamento/extrato-horas/extrato-horas.module').then( m => m.ExtratoHorasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'vagas-rh',
    loadChildren: () => import('../pages/consultas/vagas-rh/vagas-rh.module').then( m => m.VagasRhPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'folha-justificativa',
    loadChildren: () => import('../pages/folha-pagamento/folha-justificativa/folha-justificativa.module').then( m => m.FolhaJustificativaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'msg-linha',
    loadChildren: () => import('../pages/comunicacao/mensagens/msg-linha/msg-linha/msg-linha.module').then( m => m.MsgLinhaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'msg-linha-detail',
    loadChildren: () => import('../pages/comunicacao/mensagens/msg-linha/msg-linha-detail/msg-linha-detail.module').then( m => m.MsgLinhaDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'ponto-eletronico-lista',
    loadChildren: () => import('../pages/servicos/ponto-eletronico/ponto-eletronico-lista/ponto-eletronico-lista.module').then( m => m.PontoEletronicoListaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'ponto-eletronico-detail',
    loadChildren: () => import('../pages/servicos/ponto-eletronico/ponto-eletronico-detail/ponto-eletronico-detail.module').then( m => m.PontoEletronicoDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'sac',
    loadChildren: () => import('../pages/comunicacao/ouvidoria/sac/sac.module').then( m => m.SacPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'favoritos',
    loadChildren: () => import('../pages/system/favoritos/favoritos.module').then( m => m.FavoritosPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'rendimentos-detail',
    loadChildren: () => import('../pages/folha-pagamento/rendimentos-detail/rendimentos-detail.module').then( m => m.RendimentosDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'sac-detail',
    loadChildren: () => import('../pages/comunicacao/ouvidoria/sac-detail/sac-detail.module').then( m => m.SacDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'alert-modal',
    loadChildren: () => import('../shared/components/alert-modal/alert-modal.module').then( m => m.AlertModalPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'escala-rodoviario',
    loadChildren: () => import('../pages/escalas/escala-rodoviario/escala-rodoviario/escala-rodoviario.module').then( m => m.EscalaRodoviarioPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'solicitacao-detail',
    loadChildren: () => import('../pages/servicos/solicitacoes/solicitacao-detail/solicitacao-detail.module').then( m => m.SolicitacaoDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'solicitacao-nova',
    loadChildren: () => import('../pages/servicos/solicitacoes/solicitacao-nova/solicitacao-nova.module').then( m => m.SolicitacaoNovaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'recibo-ferias',
    loadChildren: () => import('../pages/folha-pagamento/recibo-ferias/recibo-ferias.module').then( m => m.ReciboFeriasPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'solicitacao-carona',
    loadChildren: () => import('../pages/comunicacao/solicita-carona/solicitacao-carona/solicitacao-carona.module').then( m => m.SolicitacaoCaronaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'solicitacao-carona-detail',
    loadChildren: () => import('../pages/comunicacao/solicita-carona/solicitacao-carona-detail/solicitacao-carona-detail.module').then( m => m.SolicitacaoCaronaDetailPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'solicitacao-carona-consulta',
    loadChildren: () => import('../pages/comunicacao/solicita-carona/solicitacao-carona-consulta/solicitacao-carona-consulta.module').then( m => m.SolicitacaoCaronaConsultaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'sac-folha',
    loadChildren: () => import('../pages/folha-pagamento/sac-folha/sac-folha/sac-folha.module').then( m => m.SacFolhaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'sac-folha-consulta',
    loadChildren: () => import('../pages/folha-pagamento/sac-folha/sac-folha-consulta/sac-folha-consulta.module').then( m => m.SacFolhaConsultaPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'sac-folha-retorno',
    loadChildren: () => import('../pages/folha-pagamento/sac-folha/sac-folha-retorno/sac-folha-retorno.module').then( m => m.SacFolhaRetornoPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'modal-tutorial',
    loadChildren: () => import('../pages/system/modal-tutorial/modal-tutorial.module').then( m => m.ModalTutorialPageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'modal-sobre-page',
    loadChildren: () => import('../pages/system/modal-sobre-page/modal-sobre-page.module').then( m => m.ModalSobrePagePageModule),
    canLoad: [AuthGruard]
  },
  {
    path: 'itinerario',
    loadChildren: () => import('../pages/escalas/escala-padrao/itinerario/itinerario.module').then( m => m.ItinerarioPageModule),
    canLoad: [AuthGruard]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
