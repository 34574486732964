import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { AlertController, MenuController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { BehaviorSubject, merge, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, retry } from "rxjs/operators";
import { Device } from '@ionic-native/device/ngx';
import { DmgeralService } from '../dmgeral/dmgeral';
import { StorageService } from '../storage/storage';
import { environment } from 'src/environments/environment';
import { Funcionario } from 'src/models/funcionario';
import { Menu } from 'src/models/menu';
import { Id } from 'src/models/id';
import { Versao } from 'src/models/versao';
import { Noticias } from 'src/models/noticias';
import { Correio } from 'src/models/correio';
import { NewNotification } from 'src/models-new/new-notification';
import { Notificacao } from 'src/models/notificacao';
import { Escala } from 'src/models/escala';
import { Agenda } from 'src/models/agenda';
import { Senha } from 'src/models/senha';
import { InfoPagePage } from 'src/pages/system/info-page/info-page';
import { Comunicacao } from 'src/models/comunicacao';
import { Tabelas } from 'src/models/tabelas';
import { NewComunicacao } from 'src/models-new/new-comunicacao';
import { Solicitacao } from 'src/models/solicitacao';
import { ConsumoComb } from 'src/models/consumo-comb';
import { Contato } from 'src/models/contato';
import { Diretrizes } from 'src/models/diretrizes';
import { Folha } from 'src/models/folha';
import { ExtratoBancoHoras } from 'src/models/extrato-bh';
import { ValeRefeicao } from 'src/models/valeRefeicao';
import { Rendimentos } from 'src/models/rendimentos';
import { Reconhecimentos } from 'src/models/reconhecimentos';
import { RedeSocial } from 'src/models/social';
import { EleicoesCipa } from 'src/models/eleicoes-cipa';
import { SaldoBcoHrs } from 'src/models/saldo-bh';
import { Colaboradores } from 'src/models/colaboradores';
import { ExtratoVr } from 'src/models/extrato-vr';
import { NewPontoEletronico } from 'src/models-new/new-ponto-eletronico';
import { NewMelhoriaViaria } from 'src/models-new/new-melhoria-viaria';
import { ExtratoHoras } from 'src/models/extrato-horas';
import { Network } from '@ionic-native/network/ngx';
import { NewReadFolha } from 'src/models-new/new-read-folha';
import { PtoEletronico } from 'src/models/pto-eletronico';
import { CriptoService } from '../cripto/cripto';
import { Parametros } from 'src/models/parametros';
import { Atalhos } from 'src/models/atalhos';
import { Ferias } from 'src/models/ferias';
import { NewReadFerias } from 'src/models-new/new-read-ferias';
import { NewPontoEmb } from 'src/models-new/new-ponto-emb';
import { QCep } from 'src/models/QCEP';
import { ModalSobrePagePage } from 'src/pages/system/modal-sobre-page/modal-sobre-page';
import { SubMenu } from 'src/models/submenu';

//const keyUser = 'funcionarioLogado';
const keyUser = 'loggedInUser';
const keyToken = 'tokenAuth';
const keyIdToken = 'tokenId';
const keyTokenMobile = 'tokenMobile';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic TkdTOk5HUw=='
  })
};

const httpOptions2 = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
  })
};

@Injectable({
  providedIn: 'root'
})
export class FirebaseProvider {
  private PATH_CONFIG = "/Configuracao";
  private PATH_FAVORITOS = "/Favoritos/";
  private PATH_FUNCIONARIOS = "/Usuarios/";
  private PATH_ESCALAS = "/Escala/";
  private PATH_CORREIO = '/Correio/';
  private PATH_VERSAO = '/Versao/';
  private PATH_SOLICITACOES = '/Solicitacoes/';
  private PATH_CONTATOS = '/Contatos/';
  private PATH_FOLHA = '/Folha/';
  private PATH_BCO_HORAS = '/BancoHoras/';
  private PATH_SALDO_BCO_HORAS = '/SaldoBcoHrs/'
  private PATH_VR = '/ValeRefeicao/';
  private PATH_EXT_VR = '/ExtratoVR/';
  private PATH_AGENDA_PESSOAL = '/AgendaPessoal/';
  private PATH_ID = '/ListaId/';
  private PATH_READ_ESCALA = '/LeuEscala/';
  private PATH_READ_MSG = '/LeuMsg/';
  private PATH_READ_RECIBO = '/LeuFolhaPgto/';
  private PATH_READ_RECIBO_FERIAS = '/LeuReciboFerias/';
  private PATH_INFOTRECHO = '/InfoTrecho/';
  private PATH_AVARIAS = '/Avarias/';
  private PATH_IMG_AVARIAS = 'appImagens/ImgAvaria/';
  private PATH_IMG_SOLICITACAO = 'appImagens/ImgSolicitacao/';
  private PATH_IMG_DOCUMENTOS = 'appImagens/ImgDocumentos/';
  private PATH_STRG_IM_FUNCIONARIO = 'appImagens/imgFuncionarios/';
  private PATH_COMUNICACAO = '/Comunicacao/';
  private PATH_SENHA = '/TrocaSenha/';
  private PATH_RECUPERA_SENHA = '/RecuperaSenha/';
  private PATH_ALT_SENHA = '/Usuarios/';
  private PATH_INFORMATIVOS = '/Informativos/';
  private PATH_READ_INFORMATIVOS = '/LeuInformativo/';
  private PATH_READ_AGENDA = '/LeuAgenda/';
  private PATH_READ_MANUAL = '/LeuManual/';
  private PATH_READ_TUTORIAL = '/LeuTutorial/';
  private PATH_TOKEN = '/Token/';
  private PATH_USUARIOS = '/Usuarios/';
  private PATH_CONEXAO = '/ConexoesAtivas/';
  private PATH_DIRETRIZ = '/Diretrizes/';
  private PATH_REDE_SOCIAL = '/RedesSociais/';
  private PATH_MEDALHAS = '/Reconhecimentos/';
  private PATH_RENDIMENTOS = '/CartaRendimentos/';
  private PATH_ALT_DADOS = '/NovosDados/';
  private PATH_CONSUMO_DIESEL = '/ConsumoDiesel/';
  private PATH_PONTO_ELETRONICO = '/PontoEletronico/';
  private PATH_DOCS_DIGITAIS = '/DocumentosDigitais/';
  private PATH_EXT_DIAS = '/ExtratoHoras/';
  private PATH_REL = 'GetPdfBase64/';
  private PATH_OUVIDORIA = '/Ouvidoria/';
  private PATH_IMG_FUNC = '/ImagemFuncionario/';
  private PATH_FERIAS = '/Ferias/';
  private PATH_PTO_EMB = '/PontosEmbarque/';
  private PATH_SAC_FOLHA = '/SacFolha/';

  private vRetPage = new Subject<any>();
  private empNgs: number = environment.config.empresa.emp;

  public vBaseUrl: string;
  public vColorBtn: string;
  public vColorMenu: string;
  public vColorMenu2: string;
  public vCssEmps: string;
  public vCssIcon: string;
  public vCssVar: string;
  public vCssVar2: string;
  public vEmpNgs: number;
  public vImgEmp: string;
  public vImgEmp2: string;
  public vImgIcon: string;
  public vNomeApp: string;
  public vVersao: string;
  public about: string;
  public nomeFuncionario: string = '';
  public comPerfil: string = '';
  public comAgenda: string;
  public funcaoFunc: string = '';

  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  userLogged: BehaviorSubject<Funcionario> = new BehaviorSubject<Funcionario>(null);
  vMenu: BehaviorSubject<Array<Menu>> = new BehaviorSubject<Array<Menu>>([]);
  isFavoritos: BehaviorSubject<Array<Atalhos>> = new BehaviorSubject<Array<Atalhos>>([]);
  pageMenu: Array<Menu>;
  tasksRef: AngularFireList<any>;
  tasks: Observable<any[]>;

  private urlRota = 'https://ngsservicos.com/aplicativos/NgFuncionario/visate/arquivos/roteiro.geojson'

  //teste git

  constructor(private db: AngularFireDatabase, private afStorage: AngularFireStorage, private ahAuth: AngularFireAuth, private _storage: StorageService,
    private _cripto: CriptoService, private router: Router, private fb: FirebaseX, public popoverController: PopoverController,
    private alertCtrl: AlertController, private dmgeral: DmgeralService, private plt: Platform, private device: Device, private menuCtrl: MenuController,
    public http: HttpClient, private network: Network, private modalCtrl: ModalController, public toastCtrl: ToastController) {
    this.startApplication();
  }

  async startApplication() {
    await this._storage.init();
    this.getConfig();

    if (this.checkConnection() === true && this.empNgs != 14) {
      this.exit();
      this.dmgeral.openTostNetwork();
    } else if (await this._storage.keyExistence(keyUser) == true) {
      this.loggedIn.next(false);
      this._storage.remove(keyToken);
    } else {
      this.loadStorage();
    }
  }

  getConfig() {
    this.vBaseUrl = environment.config.empresa.baseUrl;
    this.vColorBtn = environment.config.style.corEmpresa;
    this.vColorMenu = environment.config.style.corMenu;
    this.vColorMenu2 = environment.config.style.corMenu1;
    this.vCssEmps = environment.config.style.varCSS2;
    this.vCssVar = environment.config.style.varCSS;
    this.vCssVar2 = environment.config.style.varCSS2;
    this.vEmpNgs = environment.config.empresa.emp;
    this.vImgEmp = environment.config.style.logoEmpresa;
    this.vNomeApp = environment.config.empresa.nomeApp;
    this.vVersao = environment.config.empresa.versaoApp;
    this.vImgIcon = environment.config.style.iconeApp;
    this.vCssIcon = 'i-default';

    if (environment.config.empresa.emp == 2) {
      this.vImgEmp2 = environment.config.style.logoEmpresa2;
    }
  }

  async loadStorage() {
    this.ahAuth.authState.subscribe((auth) => {
      this._storage.get(keyUser).then((user) => {
        if ((auth != undefined && auth != null) && (user != undefined && user != undefined)) {
          this._storage.set(keyToken, this._cripto.setGemericEncrypt(JSON.stringify(user)));
          let auxUser = this._cripto.getDecryptUser(user);
          this.userLogged.next(JSON.parse(auxUser));
          this.loggedIn.next(true);
          this.montaMenu();
        }
      });
    });
  }

  autenticacao(credenciais: { cpf, senha }) {
    this.dmgeral.abreLoading();
    let newCPF = this.dmgeral.pad(credenciais.cpf, 11).replace(/[^\d]+/g, "");
    let email = newCPF + environment.config.empresa.dominioEmp;
    let newKey = newCPF;
    console.log(newKey)
    this.getId(newCPF).then(id => {
      if (id.acesso != 'S') {
        this.dmgeral.fechaLoading();
        this.dmgeral.abreAlerta('Atenção!', 'CPF não cadastrado.');
      } else {
        this.ahAuth.signInWithEmailAndPassword(email, newKey).then(autentica => {
          this.login(newCPF, credenciais.senha);
        }).catch(err => {
          this.ahAuth.createUserWithEmailAndPassword(email, newKey).then(novo => {
            this.login(newCPF, credenciais.senha);
          });
        });
      }
    });
  }

  login(cpf: string, senha: string) {
    this.getVersao().then(v => {
      if (this.vVersao < v.versao) {
        this.dmgeral.abreAlerta('Versão não atualizada.', 'Por favor, atualize o seu aplicativo.');
      } else {
        this.getFuncionario(cpf).then(funcionario => {
          let key = '';
          if (environment.config.empresa.emp === 3) {
            key = funcionario.senha;
          } else {
            key = funcionario.senha.substr(10, 30);
          }

          if (funcionario.cpf == cpf && key == senha) {
            this.dmgeral.fechaLoading();
            this.loggedIn.next(true);
            this.router.navigateByUrl('/home', { replaceUrl: true });
            this.setStorageUser(funcionario);
            this.getToken(funcionario.codEmpresa, funcionario.cpf, funcionario.registro);
            this.setLogPage2(funcionario.codEmpresa, 'LoginPage', funcionario.cpf, funcionario.registro, this.device.platform, this.device.model, this.device.version);
            this.montaMenu();
          } else {
            this.dmgeral.fechaLoading();
            this.dmgeral.abreAlerta('Atenção!', 'Usuário ou senha inválidos, repita a autenticação.');
          }
        });
      }
    });
  }

  getToken(empresa: string, idFirebase: string, registro: string) {
    if (this.plt.is("android") || this.plt.is("ios")) {
      if (idFirebase != '') {
        this.fb.getToken().then(token => {
          if (token != undefined) {
            this.setAddToken(empresa, idFirebase, token, registro);
            this.onUpTokenUser(idFirebase, token);
          }
        }).catch(err => {
          console.log('serviço não disponível.');
        });
        this.fb.onMessageReceived().subscribe(data => {
          if (data.wasTapped) {
            console.log("Received in background");
          } else {
            console.log("Received in foreground");
          };
        });
      }
    } else {
      console.log('não é mobile');
    }
  }

  exit() {
    this.menuCtrl.enable(false);
    this.loggedIn.next(false);
    this.router.navigate(['']);;
    this.ahAuth.signOut();
    this._storage.remove(keyUser);
    this._storage.remove(keyToken);
  }

  montaMenu() {
    let vPages: Array<Menu>;
    this.getMenu().subscribe(m => {
      vPages = m;
      this.vMenu.next(vPages);
      m.forEach(p => {
        if (p.title == 'Agenda' && p.acesso == 'S') {
          this.comAgenda == 'S';
        }
      });
    });
  }

  getNamePage(id: string) {
    let retorno = '';
    if (id != null && id != undefined) {
      this.vMenu.subscribe(menu => {
        menu.forEach(m => {
          if (m.id === id) {
            retorno = m.title;
          }
        });
      });
      return retorno;
    }
  }

  getNamePageByUrl(url: string) {
    let retorno = '';
    if (url != null && url != undefined) {
      this.vMenu.subscribe(menu => {
        menu.forEach(m => {
          if (m.url === url) {
            retorno = m.title;
          }
        });
      });
      return retorno;
    }
  }

  getInfoByUrl(url: string) {
    let retorno = '';
    if (url != null && url != undefined) {
      this.vMenu.subscribe(menu => {
        menu.forEach(m => {
          if (m.url === url) {
            retorno = m.infoExt;
          }
        });
      });
      return retorno;
    }
  }

  getMenuByUrl(url: string) {
    let retorno: Menu;
    if (url != null && url != undefined) {
      this.vMenu.subscribe(menu => {
        menu.forEach(m => {
          if (m.url === url) {
            retorno = m;
          }
        });
      });
      return retorno;
    }
  }

  getSubmenuByUrl(url: string) {
    let retorno: SubMenu;
    if (url != null && url != undefined) {
      this.vMenu.subscribe(menu => {
        menu.forEach(m => {
          if (m.Menu_Filho != undefined && m.Menu_Filho != null) {
            m.Menu_Filho.forEach(m => {
              if (m.url === url) {
                retorno = m;
              }
            })
          }
        });
      });
      return retorno;
    }
  }

  async fechaAPP() {
    const alert = await this.alertCtrl.create({
      header: 'Fechar o APP',
      message: 'Você realmente deseja sair?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Sair',
          handler: () => {
            this.exit();
          }
        }
      ]
    });
    await alert.present();
  }

  getHoraFb() {
    var serverTime;
    this.db.database.ref("/.info/serverTimeOffset").on('value', function (offset) {
      var offsetVal = offset.val() || 0;
      serverTime = Date.now() + offsetVal;
    });
    return serverTime;
  }

  savePage(data: any) {
    this.vRetPage.next(data);
  }

  getRetPage(): Subject<any> {
    return this.vRetPage;
  }

  async presentPopover(sobre: string) {
    const popover = await this.popoverController.create({
      component: InfoPagePage,
      componentProps: {
        textoPage: sobre
      },
      cssClass: 'custom-popover'
    });
    return await popover.present();
  }

  async openToastTutorial(funcionario: Funcionario, urlPage: string, link: string) {
    if (link) {
      const toast = await this.toastCtrl.create({
        message: 'Primeira vez? Assista o nosso tutorial.',
        duration: 5000,
        buttons: [
          {
            text: 'Assistir',
            role: 'info',
            handler: () => {
              this.dmgeral.openModalTutorial(link);
              this.addReadTutorial(funcionario.codEmpresa, funcionario.idFirebase, urlPage);
            }
          }
        ]
      });
      toast.present();
    }
  }

  //funçoes de storage
  setIdAuth(value) {
    this._storage.set(keyIdToken, value);
  }

  setStorageUser(f: Funcionario) {
    this.userLogged.next(f)
    let aux = this._cripto.setEncryptUser(f);
    this._storage.set(keyUser, aux);
  }

  getStorageUser() {
    return this.userLogged;
  }

  tokenMobile: string;
  setTokenStorage(value) {
    this._storage.set(keyTokenMobile, value);
    this.tokenMobile = value;
  }

  getTokenStorage() {
    return this.tokenMobile;
  }

  // get http

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };

  getRelatorio(parametros: string) {
    return this.http.get(this.vBaseUrl + this.PATH_REL + parametros, httpOptions).pipe(retry(2), catchError(this.handleError))
  }

  onGetJson(link: string) {
    return this.http.get(link);
  }

  onGetApproximateAddress(lat: number, lng: number) {
    let link = 'https://nominatim.openstreetmap.org/reverse?lat=' + lat + '&lon=' + lng + '&format=json'
    return this.http.get(link).pipe(map((data: any) => this.converterRespostaParaCep(data.address)));
  }

  private converterRespostaParaCep(cepNaResposta): QCep {
    let cep = new QCep();
    cep.cep = cepNaResposta.postcode;
    cep.logradouro = cepNaResposta.road;
    cep.bairro = cepNaResposta.suburb;
    cep.cidade = cepNaResposta.city;
    cep.uf = cepNaResposta.state;;
    return cep;
  }

  //Lucas - 22
  onGetFileGeoJson() {
    let link = 'https://ngsservicos.com/aplicativos/NgFuncionario/visate/arquivos/roteiro.txt';
    //return this.http.get(link).pipe(map((data: any) => data));
    //let link = 'https://firebasestorage.googleapis.com/v0/b/ngsconexoes.appspot.com/o/appArquivos%2Fgeojson%2Froteiro.json?alt=media&token=f6e3f4bc-1180-472f-956e-9a25e9424773'
    this.http.get<any>(link, httpOptions2).subscribe(data => {
      console.log(data)
    })
  }

  onGetFileGeoJsonTwo() {
    return this.http.get('/assets/files/linha.geojson');
  }

  //funções firebase
  //get == busca

  async getInfoPagina(url: string) {
    if (url) {
      let menu: Menu = this.getMenuByUrl(url);
      let submenu: SubMenu = this.getSubmenuByUrl(url);

      const modal = await this.modalCtrl.create({
        component: ModalSobrePagePage,
        swipeToClose: true,
        componentProps: {
          page: menu != undefined ? menu : submenu != undefined ? submenu : null
        }
      });
      return await modal.present();
    }
  }

  getMsgPagina(url: string) {
    if (url) {
      let mensagem: string;
      let retorno: string;
      let menu: Menu = this.getMenuByUrl(url);
      let submenu: SubMenu = this.getSubmenuByUrl(url);
      mensagem = (menu != null && menu != undefined) ? menu.msgVazio : (submenu != null && submenu != undefined) ? submenu.msgVazio : null;
      retorno = '<ion-icon name="warning-outline"></ion-icon> ' + mensagem
      this.dmgeral.openTost(retorno, 3);
    }
  }

  getLinkPagina(url: string) {
    if (url) {
      let retorno: string;
      let menu: Menu = this.getMenuByUrl(url);
      let submenu: SubMenu = this.getSubmenuByUrl(url);
      retorno = (menu != null && menu != undefined) ? menu.linkApoio : (submenu != null && submenu != undefined) ? submenu.linkApoio : null;
      return retorno;
    }
  }

  getTutorialPage(funcionario: Funcionario, urlPage: string) {
    let menu: Menu = this.getMenuByUrl(urlPage);
    if (menu != null && menu != undefined) {
      this.getAllLista2(funcionario.codEmpresa, '/LeuTutorial/', funcionario.idFirebase, 'UrlPage', urlPage)
        .then(data => {
          if (data.length === 0) {
            if (menu.linkTutorial) {
              this.openToastTutorial(funcionario, urlPage, menu.linkTutorial);
            }
          }
        });
    }
  }

  getMsgIntro(url: string) {
    let retorno: string;
    if (url) {
      let menu: Menu = this.getMenuByUrl(url);
      let submenu: SubMenu = this.getSubmenuByUrl(url);
      retorno = (menu != null && menu != undefined) ? menu.msgIntroducao : (submenu != null && submenu != undefined) ? submenu.msgIntroducao : null;
      return retorno;
    }
  }


  getAllLista(empresaFuncionario: string, path: string, subPath: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/')
        .snapshotChanges()
        .pipe(
          map(tk => {
            try {
              return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
            } catch (ex) {
              throw 'Erro ao carregar dados do firebase: ' + ex;
            }
          })
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllLista2(empresaFuncionario: string, path: string, subPath: string, campo: string, valor: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/',
        ref => ref.orderByChild(campo).equalTo(valor))
        .snapshotChanges()
        .pipe(
          map(tk => {
            try {
              return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }));
            } catch (ex) {
              throw 'Erro ao carregar dados do firebase: ' + ex;
            }
          })
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllLista3(empresaFuncionario: string, path: string, subPath: string) {
    this.tasksRef = this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/');
    this.tasks = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return this.tasks;
  }

  getAllLista4(empresaFuncionario: string, path: string, subPath: string, campo: string, valor: string) {
    this.tasksRef = this.db.list(empresaFuncionario + "/" + path + '/' + subPath + '/',
      ref => ref.orderByChild(campo).equalTo(valor));
    this.tasks = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return this.tasks;
  }

  getAllLista5(empresa: string, path: string, idFirebase: string, subPath: string) {
    this.tasksRef = this.db.list(empresa + "/" + path + '/' + idFirebase + '/' + subPath + '/');
    this.tasks = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return this.tasks;
  }


  getMenu2(): Promise<Array<Menu>> {
    return new Promise((resolve, reject) => {
      this.db.list(this.PATH_CONFIG + environment.config.empresa.versaoMenu + "/Menu/")
        .snapshotChanges()
        .pipe(
          map(menu => {
            try {
              return menu.map(c => ({ key: c.payload.key, ...c.payload.val() as Menu }));
            } catch (ex) {
              throw 'Erro ao carregar dados do menu: ' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaMenu)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }


  getMenu() {
    let vLista: Observable<Menu[]>;
    this.tasksRef = this.db.list(this.PATH_CONFIG + environment.config.empresa.versaoMenu + "/Menu/");
    vLista = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return vLista;
  }

  getFavorito(idFirebase: string) {
    let vLista: Observable<Atalhos[]>;
    this.tasksRef = this.db.list(this.PATH_FAVORITOS + idFirebase + "/Favorito/");
    vLista = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return vLista;
  }

  getParametros2() {
    let vLista: Observable<Parametros[]>;
    this.tasksRef = this.db.list(this.PATH_CONFIG + environment.config.empresa.versaoMenu + "/Parametros/" + environment.config.empresa.versaoMenu + '/');
    vLista = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return vLista;

  }

  getParametros(): Promise<Parametros> {
    return new Promise((resolve, reject) => {
      this.db.object(this.PATH_CONFIG + environment.config.empresa.versaoMenu + "/Parametros/" + environment.config.empresa.versaoMenu + '/')
        .snapshotChanges()
        .pipe(
          map(id => ({ key: id.payload.key, ...id.payload.val() as Parametros }))
        )
        .subscribe(
          data => {
            resolve(this.adaptParamentro(data));
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getOFuncionario(cpf: string) {
    let vLista: Observable<Funcionario[]>;
    this.tasksRef = this.db.list(this.PATH_FUNCIONARIOS + cpf);
    vLista = this.tasksRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    return vLista;
  }

  getOAllCorreio(empresa: string, idFirebase: string) {
    let vLista: Observable<Correio[]>;
    this.tasksRef = this.db.list(empresa + "/" + this.PATH_CORREIO + idFirebase + "/Mensagens/",
      ref => ref.orderByChild('AlteracaoLinha').equalTo('N'));
    vLista = this.tasksRef
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      ).pipe(
        map(this.adaptListaCorreio)
      )
    return vLista;
  }

  getOAllCorreioLinha(empresa: string, idFirebase: string) {
    let vLista: Observable<Correio[]>;
    this.tasksRef = this.db.list(empresa + "/" + this.PATH_CORREIO + idFirebase + "/Mensagens/",
      ref => ref.orderByChild('AlteracaoLinha').equalTo('S'));
    vLista = this.tasksRef
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      ).pipe(
        map(this.adaptListaCorreio)
      )
    return vLista;
  }

  //lucas 11/01/2022
  getOAllPtoEletronico(empresa: string, idFirebase: string) {
    let vLista: Observable<PtoEletronico[]>;
    this.tasksRef = this.db.list(empresa + "/" + this.PATH_PONTO_ELETRONICO + idFirebase + "/Pontos/",
      ref => ref.orderByChild('data'));
    vLista = this.tasksRef
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      ).pipe(
        map(this.adaptListaPtoEletronico)
      )
    return vLista;
  }
  //

  getId(cpf: string): Promise<Id> {
    return new Promise((resolve, reject) => {
      this.db.object(this.PATH_ID + cpf)
        .snapshotChanges()
        .pipe(
          map(id => ({ key: id.payload.key, ...id.payload.val() as Id }))
        )
        .subscribe(
          data => {
            resolve(this.adaptId(data));
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getVersao(): Promise<Versao> {
    return new Promise((resolve, reject) => {
      this.db.object(this.PATH_VERSAO + '/Aplicativo/')
        .snapshotChanges()
        .pipe(
          map(id => ({ key: id.payload.key, ...id.payload.val() as Versao }))
        )
        .subscribe(
          data => {
            resolve(this.adaptVersao(data));
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getFuncionario(cpf: string): Promise<Funcionario> {
    return new Promise((resolve, reject) => {
      this.db.object(this.PATH_FUNCIONARIOS + cpf)
        .snapshotChanges()
        .pipe(
          map(id => ({ key: id.payload.key, ...id.payload.val() as Funcionario }))
        )
        .subscribe(
          data => {
            resolve(this.adaptFuncionario(data));
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getEscalaDia(empresa: string, idFirebase: string, data: string): Promise<Escala> {
    return new Promise((resolve, reject) => {
      let re = /\-/gi;
      let correctDate = data.replace(re, "/");
      this.db.list(empresa + "/" + this.PATH_ESCALAS + idFirebase + "/Datas/",
        ref => ref.orderByChild('Data').equalTo(correctDate))
        .snapshotChanges()
        .pipe(
          map(escala => {
            try {
              return escala.map(c => ({ key: c.payload.key, ...c.payload.val() as Escala }));
            } catch (ex) {
              throw 'Erro ao carregar dados de ESCALA DO DIA DO FUNCIONÁRIO do Firebase: ' + ex;
            }
          })
        )
        .pipe(map(this.adaptEscala))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllEscalas(empresaFuncionario: string, idFirebase: string): Promise<Array<Escala>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_ESCALAS + idFirebase + "/Datas/")
        .snapshotChanges()
        .pipe(
          map(escalas => {
            try {
              return escalas.map(c => ({ key: c.payload.key, ...c.payload.val() as Escala }));
            } catch (ex) {
              throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaEscalas)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllCorreio(empresaFuncionario: string, idFirebase: string): Promise<Array<Correio>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_CORREIO + idFirebase + "/Mensagens/")
        .snapshotChanges()
        .pipe(
          map(mensagens => {
            try {
              return mensagens.map(c => ({ key: c.payload.key, ...c.payload.val() as Correio }));
            } catch (ex) {
              throw 'Erro ao carregar as mensagens do funcionário do Firebase: ' + ex;
            }
          })
        )
        .pipe(map(this.adaptListaCorreio))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAgendaPessoal(empresa: string, idFirebase: string): Promise<Array<Agenda>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresa + '/' + this.PATH_AGENDA_PESSOAL + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(
          map(ageGeral => {
            try {
              return ageGeral.map(c => ({ key: c.payload.key, ...c.payload.val() as Agenda }));
            } catch (ex) {
              throw 'Erro ao carregar dados da agenda geral ' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaAgendaPessoal)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getSenhaPessoal(empresa: string, idFirebase: string): Promise<Array<Senha>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresa + '/' + this.PATH_SENHA + idFirebase)
        .snapshotChanges()
        .pipe(
          map(pass => {
            try {
              return pass.map(c => ({ key: c.payload.key, ...c.payload.val() as Senha }));
            } catch (ex) {
              throw 'Erro ao carregar os dados' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaSenhaPessoal)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllInformativos(empresaFuncionario: string): Promise<Array<Noticias>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_INFORMATIVOS + 'Cards/')
        .snapshotChanges()
        .pipe(map(i => {
          try {
            return i.map(c => ({ key: c.payload.key, ...c.payload.val() as Noticias }));
          } catch (ex) {
            throw 'Erro ao carregar as mensagens da empresa do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaInformativos))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllComunicacao(empresaFuncionario: string, idFirebase: string): Promise<Array<Comunicacao>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_COMUNICACAO + idFirebase)
        .snapshotChanges()
        .pipe(
          map(c => {
            try {
              return c.map(c => ({ key: c.payload.key, ...c.payload.val() as Comunicacao }));
            } catch (ex) {
              throw 'Erro ao carregar dados do Firebase: ' + ex;
            }
          })
        )
        .pipe(
          map(this.adaptListaComunicacao)
        )
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllTabelas(empresaFuncionario: string, tabela: string): Promise<Array<Tabelas>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/Tabelas/Tabela/", ref => ref.orderByChild('TABELA').equalTo(tabela))
        .snapshotChanges()
        .pipe(map(tk => {
          try {
            return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as Tabelas }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaTabelas))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }


  getConsumoComb(empresaFuncionario: string, idFirebase: string): Promise<Array<ConsumoComb>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_CONSUMO_DIESEL + idFirebase + "/Veiculos/")
        .snapshotChanges()
        .pipe(map(bco => {
          try {
            return bco.map(c => ({ key: c.payload.key, ...c.payload.val() as ConsumoComb }));
          } catch (ex) {
            throw 'Erro ao carregar dados do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaConsumoComb))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllContatos(empresaFuncionario: string): Promise<Array<Contato>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_CONTATOS + "/Telefone/")
        .snapshotChanges()
        .pipe(map(contato => {
          try {
            return contato.map(c => ({ key: c.payload.key, ...c.payload.val() as Contato }));
          } catch (ex) {
            throw 'Erro ao carregar as mensagens da empresa do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaContatos))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllDiretrizes(empresaFuncionario: string): Promise<Array<Diretrizes>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_DIRETRIZ + 'Diretriz/')
        .snapshotChanges()
        .pipe(map(tk => {
          try {
            return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as Diretrizes }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaDiretriz))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllFolha(empresaFuncionario: string, idFirebase: string): Promise<Array<Folha>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_FOLHA + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(map(folha => {
          try {
            return folha.map(c => ({ key: c.payload.key, ...c.payload.val() as Folha }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaFolha))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllBancoHoras(empresaFuncionario: string, idFirebase: string): Promise<Array<ExtratoBancoHoras>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_BCO_HORAS + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(map(bco => {
          try {
            return bco.map(c => ({ key: c.payload.key, ...c.payload.val() as ExtratoBancoHoras }));
          } catch (ex) {
            throw 'Erro ao carregar dados  do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaBancoHoras))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getSaldoBcoHrs(empresaFuncionario: string, idFirebase: string): Promise<Array<SaldoBcoHrs>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_SALDO_BCO_HORAS + idFirebase + '/Saldo/')
        .snapshotChanges()
        .pipe(map(bco => {
          try {
            return bco.map(c => ({ key: c.payload.key, ...c.payload.val() as SaldoBcoHrs }));
          } catch (ex) {
            throw 'Erro ao carregar dados  do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaSalcoBcoHrs))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllValeRefeicao(empresaFuncionario: string, idFirebase: string): Promise<Array<ValeRefeicao>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_VR + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(map(vr => {
          try {
            return vr.map(c => ({ key: c.payload.key, ...c.payload.val() as ValeRefeicao }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaVr))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getExtratoValeRefeicao(empresaFuncionario: string, idFirebase: string): Promise<Array<ExtratoVr>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_EXT_VR + idFirebase + "/Meses/")
        .snapshotChanges()
        .pipe(map(vr => {
          try {
            return vr.map(c => ({ key: c.payload.key, ...c.payload.val() as ExtratoVr }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaExtVr))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getExtratoHoras(empresaFuncionario: string, idFirebase: string): Promise<Array<ExtratoHoras>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_EXT_DIAS + idFirebase + '/Dados/')
        .snapshotChanges()
        .pipe(map(vr => {
          try {
            return vr.map(c => ({ key: c.payload.key, ...c.payload.val() as ExtratoHoras }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaExtDia))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllRendimentos(empresaFuncionario: string, idFirebase: string): Promise<Array<Rendimentos>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_RENDIMENTOS + idFirebase + "/Rendimentos/")
        .snapshotChanges()
        .pipe(map(rendimentos => {
          try {
            return rendimentos.map(c => ({ key: c.payload.key, ...c.payload.val() as Rendimentos }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaRendimentos))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllFerias(empresaFuncionario: string, idFirebase: string): Promise<Array<Ferias>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + this.PATH_FERIAS + idFirebase + "/Ferias/")
        .snapshotChanges()
        .pipe(map(ferias => {
          try {
            return ferias.map(c => ({ key: c.payload.key, ...c.payload.val() as Ferias }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaFerias))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllReconhecimentos(empresaFuncionario: string, idFirebase: string): Promise<Array<Reconhecimentos>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_MEDALHAS + idFirebase + "/Medalhas/")
        .snapshotChanges()
        .pipe(map(medalhas => {
          try {
            return medalhas.map(c => ({ key: c.payload.key, ...c.payload.val() as Reconhecimentos }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaMedalhas))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllRedesSociais(empresaFuncionario: string): Promise<Array<RedeSocial>> {
    return new Promise((resolve, reject) => {
      this.db.list(empresaFuncionario + "/" + this.PATH_REDE_SOCIAL + '/Redes/')
        .snapshotChanges()
        .pipe(map(tk => {
          try {
            return tk.map(c => ({ key: c.payload.key, ...c.payload.val() as RedeSocial }));
          } catch (ex) {
            throw 'Erro ao carregar dados de ESCALAS DO FUNCIONÁRIO do Firebase: ' + ex;
          }
        }))
        .pipe(map(this.adaptListaRedeSocial))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getEleicoesCipa(empresa: string, data: string): Promise<EleicoesCipa> {
    return new Promise((resolve, reject) => {
      this.db.list(empresa + "/EleicoesCipa/Eleicao/",
        ref => ref.orderByChild('DATA').equalTo(data))
        .snapshotChanges()
        .pipe(
          map(escala => {
            try {
              return escala.map(c => ({ key: c.payload.key, ...c.payload.val() as EleicoesCipa }));
            } catch (ex) {
              throw 'Erro ao carregar dados do Firebase: ' + ex;
            }
          })
        )
        .pipe(map(this.adaptEleicoes))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getColaborador(empresa: string, registro: string): Promise<Colaboradores> {
    return new Promise((resolve, reject) => {
      this.db.list(empresa + "/Funcionarios/Funcionario/",
        ref => ref.orderByChild('REGISTRO').equalTo(registro))
        .snapshotChanges()
        .pipe(
          map(escala => {
            try {
              return escala.map(c => ({ key: c.payload.key, ...c.payload.val() as Escala }));
            } catch (ex) {
              throw 'Erro ao carregar dados de ESCALA DO DIA DO FUNCIONÁRIO do Firebase: ' + ex;
            }
          })
        )
        .pipe(map(this.adaptColaborador))
        .subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  //adapt
  adaptListaMenu(data): Array<Menu> {
    let list = new Array<Menu>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Menu.adapts(l));
      });
    }
    return list;
  }

  adaptId(data): Id {
    let uid = Id.adapts(data);
    return uid;
  }

  adaptVersao(data): Versao {
    let ver = Versao.adapts(data);
    return ver;
  }

  adaptParamentro(data): Parametros {
    let p = Parametros.adapts(data);
    return p;
  }

  adaptFuncionario(data): Funcionario {
    let f = Funcionario.adapts(data);
    return f;
  }

  adaptEscala(data): Escala {
    let escala = null;
    if (data.length > 0)
      escala = Escala.adapts(data[0]);
    return escala;
  }

  adaptListaCorreio(data): Array<Correio> {
    let list = new Array<Correio>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Correio.adapts(l));
      });
    }
    return list;
  }

  adaptListaAgendaPessoal(data): Array<Agenda> {
    let list = new Array<Agenda>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Agenda.adapts(l));
      });
    }
    return list;
  }

  adaptListaSenhaPessoal(data): Array<Senha> {
    let list = new Array<Senha>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Senha.adapts(l));
      });
    }
    return list;
  }

  adaptListaEscalas(data): Array<Escala> {
    let list = new Array<Escala>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Escala.adapts(l));
      });
    }
    return list;
  }

  adaptListaInformativos(data): Array<Noticias> {
    let list = new Array<Noticias>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Noticias.adapts(l));
      });
    }
    return list;
  }

  adaptListaComunicacao(data): Array<Comunicacao> {
    let list = new Array<Comunicacao>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Comunicacao.adapts(l));
      });
    }
    return list;
  }

  adaptListaTabelas(data): Array<Tabelas> {
    let list = new Array<Tabelas>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Tabelas.adapts(l));
      });
    }
    return list;
  }

  adaptListaConsumoComb(data): Array<ConsumoComb> {
    let list = new Array<ConsumoComb>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ConsumoComb.adapts(l));
      });
    }
    return list;
  }

  adaptListaContatos(data): Array<Contato> {
    let list = new Array<Contato>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Contato.adapts(l));
      });
    }
    return list;
  }

  adaptListaDiretriz(data): Array<Diretrizes> {
    let list = new Array<Diretrizes>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Diretrizes.adapts(l));
      });
    }
    return list;
  }

  adaptListaFolha(data): Array<Folha> {
    let list = new Array<Folha>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Folha.adapts(l));
      });
    }
    return list;
  }

  adaptListaBancoHoras(data): Array<ExtratoBancoHoras> {
    let list = new Array<ExtratoBancoHoras>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ExtratoBancoHoras.adapts(l));
      });
    }
    return list;
  }

  adaptListaSalcoBcoHrs(data): Array<SaldoBcoHrs> {
    let list = new Array<SaldoBcoHrs>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(SaldoBcoHrs.adapts(l));
      });
    }
    return list;
  }

  adaptListaVr(data): Array<ValeRefeicao> {
    let list = new Array<ValeRefeicao>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ValeRefeicao.adapts(l));
      });
    }
    return list;
  }


  adaptListaExtVr(data): Array<ExtratoVr> {
    let list = new Array<ExtratoVr>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ExtratoVr.adapts(l));
      });
    }
    return list;
  }

  adaptListaExtDia(data): Array<ExtratoHoras> {
    let list = new Array<ExtratoHoras>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(ExtratoHoras.adapts(l));
      });
    }
    return list;
  }

  adaptListaRendimentos(data): Array<Rendimentos> {
    let list = new Array<Rendimentos>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Rendimentos.adapts(l));
      });
    }
    return list;
  }

  adaptListaFerias(data): Array<Ferias> {
    let list = new Array<Ferias>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Ferias.adapts(l));
      });
    }
    return list;
  }

  adaptListaMedalhas(data): Array<Reconhecimentos> {
    let list = new Array<Reconhecimentos>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(Reconhecimentos.adapts(l));
      });
    }
    return list;
  }

  adaptListaRedeSocial(data): Array<RedeSocial> {
    let list = new Array<RedeSocial>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(RedeSocial.adapts(l));
      });
    }
    return list;
  }

  adaptEleicoes(data): EleicoesCipa {
    let eleicao = null;
    if (data.length > 0)
      eleicao = EleicoesCipa.adapts(data[0]);
    return eleicao;
  }

  adaptColaborador(data): Colaboradores {
    let colab = null;
    if (data.length > 0)
      colab = Colaboradores.adapts(data[0]);
    return colab;
  }

  adaptListaPtoEletronico(data): Array<PtoEletronico> {
    let list = new Array<PtoEletronico>();
    if (data != null && data != undefined && data.length > 0) {
      data.forEach(l => {
        list.push(PtoEletronico.adapts(l));
      });
    }
    return list;
  }


  //set == adiciona
  setAddToken(empresaFuncionario: string, idFirebase: string, token: string, registro: string) {
    this.db.list(empresaFuncionario + this.PATH_TOKEN + idFirebase).set('0', {
      Token: token,
      Registro: registro
    })
  }

  addReadEscala(empresaFuncionario: string, idFirebase: string, dtLeitura: string) {
    this.db.list(empresaFuncionario + this.PATH_READ_ESCALA + idFirebase + "/").push({
      DataLeitura: dtLeitura
    })
  }

  addReadAgenda(empresaFuncionario: string, idFirebase: string, idEvento: string, dtLeitura: string) {
    this.db.list(empresaFuncionario + this.PATH_READ_AGENDA + idFirebase + '/').push({
      idEvento: idEvento,
      DataLeitura: dtLeitura
    })
  }

  addReadMsg(empresaFuncionario: string, idFirebase: string, idMsg: string, dtLeitura: string) {
    this.db.list(empresaFuncionario + this.PATH_READ_MSG + idFirebase + "/").push({
      IdMsg: idMsg,
      DataLeitura: dtLeitura
    })
  }

  setLogPage2(empresaFuncionario: string, page: string, idFirebase: string, registro: string, sistema: string, modelo: string, versao: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_CONEXAO + '/' + page + '/' + idFirebase).push({
      CPF: idFirebase,
      Registro: registro,
      DataEntrada: this.dmgeral.getDataCompleta(),
      Plataforma: sistema,
      Modelo: modelo,
      VersaoSistema: versao
    })
  }

  setNewNotification(empresa: string, id: string, tipo: string) {
    let objeto: NewNotification = new NewNotification();
    objeto.data = this.dmgeral.getDataAtual();
    objeto.url = this.buscaInfo('url', tipo);
    objeto.texto = this.buscaInfo('txt', tipo);
    objeto.lida = 'false';
    objeto.tipo = tipo;

    this.db.database.ref(empresa + '/Notificacoes/' + id).push(objeto);
  }

  setAddComunicacao(empresa: string, idFirebase: string, objeto: NewComunicacao) {
    var newKey = this.db.database.ref().child('Comunicacao').push().key;
    let objeto2 = {
      DataInclusao: this.dmgeral.getDataCompleta(),
      CPF: idFirebase
    }

    this.db.database.ref(empresa + this.PATH_COMUNICACAO + '/' + idFirebase + '/' + newKey).set(objeto);
    this.db.database.ref(empresa + '/NovasComunicacao/' + 'Novas/' + newKey).set(objeto2);
  }

  setReadReciboPgto(vFuncionario: Funcionario, vFolha: Folha) {
    this.db.list(vFuncionario.codEmpresa + this.PATH_READ_RECIBO + vFuncionario.idFirebase + "/").push({
      DataLeitura: this.dmgeral.getDataCompleta(),
      Mes: vFolha.mes,
      Ano: vFolha.ano,
      Tipo: vFolha.tipo,
      Sem: vFolha.semana,
    })
  }
  setAceitaReciboPgto(vFuncionario: Funcionario, objeto: NewReadFolha) {
    var newPostKey = this.db.database.ref().child('LeuFolhaPgto').push().key;
    return this.db.database.ref(vFuncionario.codEmpresa + this.PATH_READ_RECIBO + vFuncionario.idFirebase + "/" + newPostKey)
      .set(objeto)
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO')
  }

  setAceitaReciboFerias(vFuncionario: Funcionario, objeto: NewReadFerias) {
    var newPostKey = this.db.database.ref().child('LeuReciboFerias').push().key;
    return this.db.database.ref(vFuncionario.codEmpresa + this.PATH_READ_RECIBO_FERIAS + vFuncionario.idFirebase + "/" + newPostKey)
      .set(objeto)
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO')
  }

  setAddTrecho(empresaFuncionario: string, idFirebase: string, dtInc: string, registro: string, localAprox: string, ptoRef: string, obs: string) {
    this.db.list(empresaFuncionario + '/' + this.PATH_INFOTRECHO + idFirebase + "/").push({
      DataInc: dtInc,
      Registro: registro,
      LocalAprox: localAprox,
      PontoRef: ptoRef,
      Observacao: obs
    })
  }

  addReadManualColab(empresa: string, idFirebase: string, versao: string) {
    this.db.list(empresa + this.PATH_READ_MANUAL + idFirebase + "/").push({
      DataLeitura: this.dmgeral.getDataCompleta(),
      Versao: versao
    })
  }

  setReadPolitica(funcionario: Funcionario) {
    var newKey = this.db.database.ref().child('LeuTermoPrivacidade').push().key;
    let objeto = {
      CPF: funcionario.idFirebase,
      DataLeitura: this.dmgeral.getDataCompleta(),
      Registro: funcionario.registro
    }
    funcionario.lerTermo = 'N'
    this._storage.remove(keyUser);
    this.setStorageUser(funcionario);
    this.db.database.ref(funcionario.codEmpresa + '/LeuTermoPrivacidade/' + funcionario.idFirebase + "/" + newKey).set(objeto);
    this.db.database.ref(this.PATH_ALT_SENHA + funcionario.idFirebase).update({ Ler_Termo: 'N' });
  }

  addReadInfo(empresaFuncionario: string, idFirebase: string, idMsg: string) {
    var newKey = this.db.database.ref().child('LeuInformativo').push().key;
    let objeto = {
      IdInfo: idMsg,
      DataLeitura: this.dmgeral.getDataCompleta(),
      Data: this.dmgeral.getDataAtual()
    }

    this.db.database.ref(empresaFuncionario + this.PATH_READ_INFORMATIVOS + idFirebase + "/" + newKey).set(objeto);
    this.db.database.ref(empresaFuncionario + '/InformativosLidos/' + idFirebase + "/" + newKey).set(objeto);
  }

  addReadTutorial(empresaFuncionario: string, idFirebase: string, url: string) {
    var newKey = this.db.database.ref().child('LeuTutorial').push().key;
    let objeto = {
      UrlPage: url,
      DataLeitura: this.dmgeral.getDataCompleta(),
    }
    this.db.database.ref(empresaFuncionario + this.PATH_READ_TUTORIAL + idFirebase + '/' + newKey).set(objeto);

  }

  onAddSolicitacao(empresa: string, idFirebase: string, values: string) {
    var newPostKey = this.db.database.ref().child('Solicitacoes').push().key;
    let objeto = {
      DataInclusao: this.dmgeral.getDataCompleta(),
      CPF: idFirebase
    }

    this.db.database.ref(empresa + '/NovasSolicitacoes/Novas/' + newPostKey).set(objeto);
    return this.db.database.ref(empresa + this.PATH_SOLICITACOES + idFirebase + "/" + newPostKey)
      .set(values)
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO')
  }

  onAddRecuperaSenha(cpf: string, recupera) {
    this.db.list(this.PATH_RECUPERA_SENHA + cpf + '/').push({
      RecuperaEmail: recupera
    });
  }

  onAddSenha(empresaFuncionario: string, idFirebase: string, registro: string, novaSenha: string, email: string) {
    if (email != null && email != undefined && email != '') {
      this.db.list(empresaFuncionario + this.PATH_SENHA + idFirebase + "/").push({
        DataTroca: this.dmgeral.getDataCompleta(),
        NovaSenha: novaSenha,
        Registro: registro,
        Email: email
      });
    } else {
      this.db.list(empresaFuncionario + this.PATH_SENHA + idFirebase + "/").push({
        DataTroca: this.dmgeral.getDataCompleta(),
        NovaSenha: novaSenha,
        Registro: registro
      });
    }
  }

  onAddAvaria(empresaFuncionario: string, idFirebase: string, dtInc: string, registro: string, veiculo: string, linha: string, obsGeral: string, descAvaria: string,
    id: string, qtd: number) {
    this.db.list(empresaFuncionario + '/' + this.PATH_AVARIAS + idFirebase + '/').push({
      DataInc: dtInc,
      Registro: registro,
      Veiculo: veiculo,
      Linha: linha,
      ObsGeral: obsGeral,
      DescriAvaria: descAvaria,
      Id: id,
      QtdeImgs: qtd
    })
  }

  onAddPontoEletronico(empresaFuncionario: string, idFirebase: string, ponto: NewPontoEletronico) {
    var newKey = this.db.database.ref().child('PontoEletronico').push().key;

    let objeto = {
      Data: this.dmgeral.getDataAtual(),
      cpf: idFirebase
    }

    this.db.database.ref(empresaFuncionario + this.PATH_PONTO_ELETRONICO + idFirebase + "/Pontos/" + newKey).set(ponto);
    this.db.database.ref(empresaFuncionario + '/NovoPontoEletronico/' + idFirebase + "/" + newKey).set(objeto);
  }

  onAddDocDigital(funcionario: Funcionario, id: string, imagem: string, tipo: string) {
    this.db.list(funcionario.codEmpresa + this.PATH_DOCS_DIGITAIS + funcionario.idFirebase + '/').push({
      cpf: funcionario.cpf,
      dt_inc: this.dmgeral.getDataAtual(),
      id_img: id,
      url_img: imagem,
      registro: funcionario.registro,
      tp_doc: tipo
    });
  }

  onAddMelhoriaViaria(funcionario: Funcionario, newMelhoria: NewMelhoriaViaria) {
    this.db.list(funcionario.codEmpresa + '/' + this.PATH_INFOTRECHO + funcionario.idFirebase + "/").push({
      DataInc: newMelhoria.data,
      Registro: newMelhoria.registro,
      PontoRef: newMelhoria.ponto_ref,
      Observacao: newMelhoria.obs,
      ComImg: newMelhoria.com_img,
      IdImg: newMelhoria.id_img,
      QtdImg: newMelhoria.qtd_img
    })
  }

  onAddOuvidoria(empresa: string, protocolo: string, objeto: any) {
    var newKey = this.db.database.ref().child('Ouvidoria').push().key;
    let objeto2 = {
      DataInclusao: this.dmgeral.getDataCompleta(),
      Protocolo: protocolo
    }
    this.db.database.ref(empresa + '/NovaOuvidoria/' + 'Novas/' + newKey).set(objeto2);
    return this.db.database.ref(empresa + this.PATH_OUVIDORIA + 'Ouvidorias/' + newKey)
      .set(objeto)
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO');
  }


  onAddFavorito(idFirebase: string, objeto: Array<Atalhos>) {
    return this.db.database.ref(this.PATH_FAVORITOS + idFirebase + "/Favorito/")
      .set(objeto)
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO');
  }

  //Lucas 26/10/2022
  onAddPointEmb(empresa: string, idFirebase: string, dataNode: string, objeto: NewPontoEmb) {
    var newKey = this.db.database.ref().child('PontosEmbarque').push().key;
    let objeto2 = {
      DataInclusao: this.dmgeral.getDataCompleta(),
      CPF: idFirebase
    }
    this.db.database.ref(empresa + '/NovoPontoEmbarque/Novos/' + newKey).set(objeto2);
    this.db.database.ref(empresa + '/LocaisMapa/Datas/' + dataNode + '/' + newKey).set(objeto)
    return this.db.database.ref(empresa + this.PATH_PTO_EMB + idFirebase + '/Pontos/' + newKey)
      .set(objeto)
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO');

  }

  onAddSacFolha(empresa: string, idFirebase: string, registro: string, objeto: any) {
    var newKey = this.db.database.ref().child('SacFolha').push().key;
    let objeto2 = {
      DataInclusao: this.dmgeral.getDataCompleta(),
      CPF: idFirebase,
      Registro: registro
    }
    this.db.database.ref(empresa + '/NovaSacFolha/' + 'Novas/' + newKey).set(objeto2);
    return this.db.database.ref(empresa + this.PATH_SAC_FOLHA + idFirebase + '/' + newKey)
      .set(objeto)
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO');
  }



  //del == delete
  onDelMsg(funcionario: Funcionario, idFire: string, idMsg: string) {
    this.db.database.ref(funcionario.codEmpresa + '/MensagensDeletadas/' + funcionario.cpf).push({
      IdMsg: idMsg,
      DtExc: this.dmgeral.getDataCompleta(),
      CPF: funcionario.cpf,
      Registro: funcionario.registro
    })
    return this.db.database.ref(funcionario.codEmpresa + this.PATH_CORREIO + funcionario.cpf + '/Mensagens/' + idFire)
      .remove()
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO');
  }


  onDelPtoEmb(funcionario: Funcionario, chave: string) {
    this.db.database.ref(funcionario.codEmpresa + '/PontosEmbarqueDeletados/' + funcionario.cpf).push({
      IdKey: chave,
      DtExc: this.dmgeral.getDataCompleta(),
      CPF: funcionario.cpf,
      Registro: funcionario.registro
    })
    return this.db.database.ref(funcionario.codEmpresa + this.PATH_PTO_EMB + funcionario.idFirebase + '/Pontos/' + chave)
      .remove()
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO');
  }

  //up == atualiza
  onUpTokenUser(funcionario: string, newToken: string) {
    this.db.database.ref(this.PATH_USUARIOS + funcionario).update({
      Token: newToken
    });
  }

  onUpAcceptFolha(funcionario: Funcionario, folha: Folha) {
    this.db.database.ref(funcionario.codEmpresa + this.PATH_FOLHA + funcionario.idFirebase + '/Meses/' + folha.idFirebase).update({
      Confere_Recibo: 'True'
    });
  }

  onUpAcceptFerias(funcionario: Funcionario, id: string) {
    this.db.database.ref(funcionario.codEmpresa + this.PATH_FERIAS + funcionario.idFirebase + '/Ferias/' + id).update({
      Leu_Recibo: 'True'
    });
  }

  onUpReadMsgFunc(empresaFuncionario: string, funcionario: string, idFirebase: string, lida: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_CORREIO + funcionario + "/Mensagens/" + idFirebase).update({
      Lida: lida
    });
  }

  onUpReadMsgAlertFunc(empresaFuncionario: string, funcionario: string, idFirebase: string, lida: string, alerta: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_CORREIO + funcionario + "/Mensagens/" + idFirebase).update({
      Lida: lida,
      Alerta: alerta
    });
  }

  onUpReadEventoFunc(empresaFuncionario: string, idFirebase: string, idEvento: string, lida: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_AGENDA_PESSOAL + idFirebase + '/Meses/' + idEvento).update({
      Lida: lida
    });
  }

  onUpReadRetEscala(empresaFuncionario: string, funcionario: string, chave: string, lida: string) {
    this.db.database.ref(empresaFuncionario + this.PATH_COMUNICACAO + funcionario + '/' + chave).update({
      lidaResposta: lida
    });
  }

  aceiteSolEscala(empresaFuncionario: string, cpfOri, funcionario: string, chave: string, idFire: string, nroMsg: string, aceite: string) {
    let dt = this.dmgeral.getDataCompleta();
    let objeto2 = {
      DataInclusao: this.dmgeral.getDataCompleta(),
      CPF: cpfOri
    }

    this.db.database.ref(empresaFuncionario + this.PATH_COMUNICACAO + cpfOri + '/' + chave).update({
      aceite_troca: aceite,
      dt_aceite: dt
    });

    this.db.database.ref(empresaFuncionario + '/NovasComunicacao/Novas/' + chave).set(objeto2);
    this.onUpReadMsgAlertFunc(empresaFuncionario, funcionario, idFire, 'true', 'N');
    this.addReadMsg(empresaFuncionario, funcionario, nroMsg, dt);
  }

  onUpReadNotificacao(empresa: string, id: string, chave: string, tipo: string) {
    var newKey = this.db.database.ref().child('LeuNotificacao').push().key;
    let objeto = {
      KeyNotificacao: chave,
      DataLeitura: this.dmgeral.getDataCompleta(),
      Tipo: tipo
    }

    this.db.database.ref(empresa + '/Notificacoes/' + id + '/' + chave).remove();
    this.db.database.ref(empresa + '/LeuNotificacao/' + id + "/" + newKey).set(objeto);
  }

  onUpDados(empresa: string, idFirebase: string, funcionario: Funcionario) {
    this.db.database.ref(this.PATH_USUARIOS + idFirebase).update({
      Telefone: funcionario.telefone,
      Celular: funcionario.celular,
      Email: funcionario.email,
      DDDTelefone: funcionario.dddFone,
      DDDCelular: funcionario.dddCel,
      Atualizar_Dados: 'N'
    });


    this.db.database.ref(empresa + this.PATH_ALT_DADOS + idFirebase + '/').update({
      Registro: funcionario.registro,
      CPF: funcionario.cpf,
      New_Telefone_DDD: funcionario.dddFone,
      New_Telefone: funcionario.telefone,
      New_Celular_DDD: funcionario.dddCel,
      New_Celular: funcionario.celular,
      New_Email: funcionario.email,
      Data_Troca: this.dmgeral.getDataCompleta()
    });

    this._storage.remove(keyUser);
    this.setStorageUser(funcionario);
  }

  onUpImgUser(f: Funcionario, newImg: string) {
    var newKey = this.db.database.ref().child('ImagemFuncionario').push().key;
    f.imagem = newImg;
    this._storage.remove(keyUser);
    this.setStorageUser(f);

    this.db.database.ref(this.PATH_USUARIOS + f.idFirebase).update({
      Imagem: newImg
    });

    return this.db.database.ref(f.codEmpresa + this.PATH_IMG_FUNC + f.idFirebase + '/' + newKey)
      .set({
        Registro: f.registro,
        CPF: f.cpf,
        DataInclusao: this.dmgeral.getDataCompleta(),
        Img: newImg
      })
      .then(res => res = 'OK')
      .catch(err => err = 'ERRO');
  }

  onUpSenhaUser(funcionario: string, senha: string, situacao: string, email: string) {
    if (email != null && email != undefined && email != '') {
      this.db.database.ref(this.PATH_ALT_SENHA + funcionario).update({
        Senha: senha,
        Trocar_Senha: situacao,
        Email: email
      });
    } else {
      this.db.database.ref(this.PATH_ALT_SENHA + funcionario).update({
        Senha: senha,
        Trocar_Senha: situacao
      });
    }
  }


  //upload
  uploadImageAvaria(imageURI, name: string) {
    let newName = name;
    const storageRef: AngularFireStorageReference = this.afStorage.ref(this.PATH_IMG_AVARIAS);
    return new Promise<any>((resolve, reject) => {
      let imageRef = storageRef.child(newName);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.downloadURL)
          }, err => {
            reject(err);
          })
      })
    })
  }

  uploadImageSolicitacao(imageURI, name: string) {
    let newName = name;
    const storageRef: AngularFireStorageReference = this.afStorage.ref(this.PATH_IMG_SOLICITACAO);
    return new Promise<any>((resolve, reject) => {
      let imageRef = storageRef.child(newName);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.downloadURL)
          }, err => {
            reject(err);
          })
      })
    })
  }


  uploadDocumentos(img, name: string) {
    const storageRef: AngularFireStorageReference = this.afStorage.ref(this.PATH_IMG_DOCUMENTOS);
    return new Promise<any>((resolve, reject) => {
      let imageRef = storageRef.child(name);
      this.encodeImageUri(img, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.ref.getDownloadURL())
          }, err => {
            reject(err);
          })
      })
    })
  }

  uploadImage(path, imageURI, name: string) {
    let newName = name;
    const storageRef: AngularFireStorageReference = this.afStorage.ref(path);
    return new Promise<any>((resolve, reject) => {
      let imageRef = storageRef.child(newName);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.downloadURL)
          }, err => {
            reject(err);
          })
      })
    })
  }

  uploadImgUser(img, name: string) {
    const storageRef: AngularFireStorageReference = this.afStorage.ref(this.PATH_STRG_IM_FUNCIONARIO);
    return new Promise<any>((resolve, reject) => {
      let imageRef = storageRef.child(name);
      this.encodeImageUri(img, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            resolve(snapshot.ref.getDownloadURL())
          }, err => {
            reject(err);
          })
      })
    })
  }

  encodeImageUri(imageUri, callback) {
    var c = document.createElement('canvas');
    var ctx = c.getContext("2d");
    var img = new Image();
    img.onload = function () {
      var aux: any = this;
      c.width = aux.width;
      c.height = aux.height;
      ctx.drawImage(img, 0, 0);
      var dataURL = c.toDataURL("image/jpeg");
      callback(dataURL);
    };
    img.src = imageUri;
  };


  //funções diversas
  buscaInfo(tpDado: string, page: string) {
    let retorno = '';
    if (tpDado === 'url' && page === 'I') {
      retorno = '/noticias';
    } else if (tpDado === 'url' && page === 'M') {
      retorno = '/mensagem';
    } else if (tpDado == 'url' && page === 'E') {
      retorno = '/comunicacao-consulta';
    } else if (tpDado === 'txt' && page === 'I') {
      retorno = 'Você possui novos informativos.';
    } else if (tpDado === 'txt' && page === 'M') {
      retorno = 'Você possui novas mensagens.';
    } else if (tpDado == 'txt' && page === 'E') {
      retorno = 'Você possui novas respostas na escala.';

    }
    return retorno;
  }

  montaNotificacao(empresa: string, cpf: string) {
    let vInformativos: Array<Noticias>;
    let vInfoLido: Array<any>;
    let vMsgGeral: Array<Correio>;
    let vSolEsc: Array<Comunicacao>;
    const tempM = [];
    let tempI = [];
    const tempE = [];
    var a = [];
    var b = []

    this.loggedIn.subscribe(l => {
      if (l === true && l != null) {
        this.getAllInformativos(empresa).then(info => {
          vInformativos = info;
          this.getAllLista(empresa, 'InformativosLidos', cpf).then(async data => {
            vInfoLido = data;

            vInformativos.forEach(i => {
              a.push(i.idNews);
            });

            vInfoLido.forEach(j => {
              b.push(j.IdInfo)
            });

            tempI = a.filter(temp => !b.includes(temp));

            if (tempI.length > 0 && await this.buscaNotificacao(empresa, cpf, 'I') == false) {
              this.setNewNotification(empresa, cpf, 'I');
            }
          });
        });

        this.getAllCorreio(empresa, cpf).then(async func => {
          vMsgGeral = func;
          if (vMsgGeral.length > 0) {
            vMsgGeral.forEach(m => {
              if (m.lida == 'false' && m.alerta != 'S' && m.tituloAlerta == '') {
                tempM.push(m.idMsg);
              }
            });
            if (tempM.length > 0 && await this.buscaNotificacao(empresa, cpf, 'M') == false) {
              this.setNewNotification(empresa, cpf, 'M')
            }
          }
        });

        if (this.vEmpNgs === 4) {
          this.getAllComunicacao(empresa, cpf).then(async comunic => {
            vSolEsc = comunic;
            if (vSolEsc.length > 0) {
              vSolEsc.forEach(c => {
                if (c.lida == undefined && c.dt_resposta != null && c.dt_resposta != undefined) {
                  tempE.push(c.id);
                }
              });
              if (tempE.length > 0 && await this.buscaNotificacao(empresa, cpf, 'E') == false) {
                this.setNewNotification(empresa, cpf, 'E')
              }

            }
          });
        }
      }
    });
  }

  async buscaNotificacao(empresa: string, cpf: string, tipo: string) {
    let notificacao: Array<Notificacao>;
    return this.getAllLista2(empresa, 'Notificacoes', cpf, 'tipo', tipo).then(data => {
      notificacao = data;
      if (notificacao.length > 0) {
        return true
      } else {
        return false
      }
    });
  }

  checkConnection() {
    if (this.network.type === 'none') {
      return true
    } else {
      return false
    }
  }

  //Lucas 24/01/2022 - habilitado o teste da empNgs != 14. Esta variável não testa a conexão.
  onToastNetworkAuto() {
    if (this.checkConnection() === true && this.empNgs != 14) {
      this.dmgeral.openTostNetwork();
    }
  }


}