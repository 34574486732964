import { FeriasMeses } from "./ferias-meses";
import { FeriasVerbas } from "./ferias-verbas";
import { Meses } from "./mes";

export class Ferias {
    idFirebase: string;
    agPgto: string;
    bcoPgto: string;
    cnpj: string;
    cpf: string;
    ctps: string;
    contaCorrente: string;
    cidade: string;
    depIrf: string;
    dias: string;
    diasFalta: string;
    dtAdm: string;
    dtAqFim: string;
    dtAqIni: string;
    dtEmi: string;
    dtFim: string;
    dtIni: string;
    dtPgto: string;
    dtRet: string;
    funcao: string;
    liquido: string;
    liquidoExt: string;
    nome: string;
    registro: string;
    salario: string;
    vencimentos: string;
    descontos: string;
    leuRecibo: string;
    meses: Array<FeriasMeses>;

    constructor(idFirebase: string, agPgto: string, bcoPgto: string, cnpj: string, cpf: string, ctps: string, contaCorrente: string, cidade: string, depIrf: string, dias: string, diasFalta: string,
        dtAdm: string, dtAqFim: string, dtAqIni: string, dtEmi: string, dtFim: string, dtIni: string, dtPgto: string, dtRet: string, funcao: string, liquido: string,
        liquidoExt: string, nome: string, registro: string, salario: string, vencimentos: string, descontos: string, leuRecibo: string, meses: Array<FeriasMeses>) {
        this.idFirebase = idFirebase;
        this.agPgto = agPgto;
        this.bcoPgto = bcoPgto;
        this.cnpj = cnpj;
        this.cpf = cpf;
        this.ctps = ctps;
        this.contaCorrente = contaCorrente;
        this.cidade = cidade;
        this.depIrf = depIrf;
        this.dias = dias;
        this.diasFalta = diasFalta;
        this.dtAdm = dtAdm;
        this.dtAqFim = dtAqFim;
        this.dtAqIni = dtAqIni;
        this.dtEmi = dtEmi;
        this.dtIni = dtIni;
        this.dtFim = dtFim;
        this.dtPgto = dtPgto;
        this.dtRet = dtRet;
        this.funcao = funcao;
        this.liquido = liquido;
        this.liquidoExt = liquidoExt;
        this.nome = nome;
        this.registro = registro;
        this.salario = salario;
        this.vencimentos = vencimentos;
        this.descontos = descontos;
        this.leuRecibo = leuRecibo;
        this.meses = meses;
    }

    static adapts(obj: any): Ferias {
        let idFirebase = obj.key
        let agPgto = obj.Ag_Pgto;
        let bcoPgto = obj.Bco_Pgto;
        let cnpj = obj.CGC;
        let cpf = obj.CPF;
        let ctps = obj.CTPS;
        let contaCorrente = obj.C_Corrente;
        let cidade = obj.Cidade;
        let depIrf = obj.Dep_Irf;
        let dias = obj.Dias_Ferias;
        let diasFalta = obj.Dias_Falta;
        let dtAdm = obj.Dt_Adm;
        let dtAqFim = obj.Dt_Aqf;
        let dtAqIni = obj.Dt_Aqi;
        let dtEmi = obj.Dt_Emi;
        let dtFim = obj.Dt_F;
        let dtIni = obj.Dt_I;
        let dtPgto = obj.Dt_Pgto;
        let dtRet = obj.Dt_Ret;
        let funcao = obj.Funcao;
        let liquido = obj.Liquido;
        let liquidoExt =  obj.Liquido_Extenso;
        let nome = obj.Nome;
        let registro = obj.Registro;
        let salario = obj.Salario;
        let vencimentos = obj.Vencimentos;
        let descontos = obj.Descontos;
        let leuRecibo = obj.Leu_Recibo;

        let meses = this.adaptMeses(obj.Meses);

        return new Ferias(idFirebase, agPgto, bcoPgto, cnpj, cpf, ctps, contaCorrente, cidade, depIrf, dias, diasFalta, dtAdm, dtAqFim, dtAqIni, dtEmi, dtFim,
            dtIni, dtPgto, dtRet, funcao, liquido, liquidoExt, nome, registro, salario, vencimentos, descontos, leuRecibo, meses);
    }

    static adaptMeses(data): Array<FeriasMeses> {
        let list = new Array<FeriasMeses>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpObjeto(l));
            });
        }

        return list;
    }

    static adatpObjeto(obj: any): FeriasMeses {
        let ano = obj.ano;
        let descontos = obj.Descontos;
        let mes = obj.mes;
        let mesExtenso = this.getMES(obj.mes);
        let vencimentos = obj.Vencimentos;
        let verbas = obj.Verbas;

        return new FeriasMeses(ano, descontos, mes, mesExtenso, vencimentos, verbas);
    }

    static getMES(mes: string): string {
        let nome = '';

        let weekdays = new Array(12);
        weekdays[1] = "jan";
        weekdays[2] = "fev";
        weekdays[3] = "mar";
        weekdays[4] = "abr";
        weekdays[5] = "mai";
        weekdays[6] = "jun";
        weekdays[7] = "jul";
        weekdays[8] = "ago";
        weekdays[9] = "set";
        weekdays[10] = "out";
        weekdays[11] = "nov";
        weekdays[12] = "dez";

        nome = weekdays[mes];

        return nome;
    }

}