import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrMaskerModule } from 'br-mask';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Badge } from '@ionic-native/badge/ngx';
import { Camera } from '@ionic-native/Camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { AuthGruard } from 'src/guards/auth-gruard';
import { AuthLoginGuard } from 'src/guards/auth-login';
import { File } from '@awesome-cordova-plugins/file/ngx';
//import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx'
import { DmgeralService } from 'src/provider/dmgeral/dmgeral';
import { FirebaseProvider } from 'src/provider/firebase/firebase';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { CriptoService } from 'src/provider/cripto/cripto';
import { SharedModule } from 'src/shared/modulos/shared.module';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

console.log('Voce esta na empresa: ' + environment.config.empresa.emp);
@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.config.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NgxMaskModule.forRoot(maskConfig),
    BrMaskerModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Badge,
    Camera,
    Device,
    FirebaseX,
    InAppBrowser,
    Screenshot,
    SocialSharing,
    Geolocation,
    FirebaseProvider,
    DmgeralService,
    CriptoService,
    AuthGruard,
    AuthLoginGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    FileOpener,
    FileTransfer,
    DocumentViewer,
    AppVersion,
    Network
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
