import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Menu } from 'src/models/menu';
import { DmgeralService } from 'src/provider/dmgeral/dmgeral';

@Component({
  selector: 'app-modal-sobre-page',
  templateUrl: './modal-sobre-page.html',
  styleUrls: ['./modal-sobre-page.scss'],
})
export class ModalSobrePagePage implements OnInit {
  @Input() page: Menu;
  comTutorial: boolean = false

  constructor(private dmgeral: DmgeralService, public modalController: ModalController) { }

  ngOnInit() {
    this.comTutorial = this.page.linkTutorial != '' ? true : false;
  }

  onOpen() {
    if (this.page.linkTutorial != null && this.page.linkTutorial != undefined) {
      this.dmgeral.openModalTutorial(this.page.linkTutorial);
    }
  }

}
